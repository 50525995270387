.poem-container {
    position: fixed;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    background: url('https://www.transparenttextures.com/patterns/paper.png');
    /* Paper texture */
    background-color: rgba(255, 255, 255, 0.9);
    /* White background with slight transparency */
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 40px;
    /* Reduced padding for a smaller container */
    width: 70%;
    /* Reduced width for a smaller container */
    max-width: 600px;
    /* Reduced max-width for a smaller container */
    max-height: 70vh;
    /* Reduced max-height for a smaller container */
    overflow-y: auto;
    /* Added to make the container scrollable */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    text-align: center;
    font-family: 'Georgia', serif;
    color: #333;
    line-height: 1.6;
}

h2 {
    font-size: 30px;
    margin-bottom: 20px;
    color: #2c3e50;
}

p {
    font-size: 20px;
    margin: 15px 0;
    color: #444;
}

.poem-container p:nth-child(2) {
    font-style: italic;
    margin-bottom: 30px;
    color: #2c3e50;
}

.poem-container p:last-child {
    margin-bottom: 0;
}