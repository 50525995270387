.last-words-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    font-family: 'Dancing Script', cursive;
}

.last-words-label {
    font-size: 24px;
    margin-bottom: 20px;
    color: #4a4a4a;
}

.last-words-input {
    width: 100%;
    max-width: 600px;
    height: 200px;
    padding: 20px;
    border: none;
    border-radius: 10px;
    background-image: url('https://www.transparenttextures.com/patterns/old-mathematics.png');
    background-color: #f5f5dc;
    font-family: 'Dancing Script', cursive;
    font-size: 20px;
    line-height: 1.5;
    color: #333;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    resize: none;
}

.last-words-input::placeholder {
    color: #aaa;
    font-style: italic;
}

.submit-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #4a4a4a;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.submit-button:hover {
    background-color: #333;
}